<template>
  <b-card
    no-body
    class="mb-0"
  >
    <div class="d-flex align-items-center flex-row header p-2">
      <speaker-icon class="speaker-icon text-primary" />
      <h1 class="text-primary font-weight-bolder ml-1 mb-0">
        My Messages
      </h1>
    </div>
    <div
      v-if="!isLoaded"
      class="text-center p-5"
    >
      <b-spinner />
    </div>
    <template v-else>
      <b-table
        v-if="isLoaded"
        ref="refshipmentListTable"
        class="position-relative notification-table"
        :items="emailList"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        show-empty
        empty-text="No Messages Available"
        @row-clicked="openPreviewMail"
      >
        <template #cell(sentDate)="data">
          <span class="text-nowrap">
            <feather-icon
              icon="ClockIcon"
              size="18"
              class="mr-50 color-black"
            />
            {{ formattedDate(data.item.sentDate) }}
          </span>
        </template>
        <template #cell(message)="data">
          <div class="text-truncate">
            {{ removeHtmlTags(data.item.message) }}
          </div>
        </template>
      </b-table>
      <div
        v-if="hasPagination"
        class="mx-2 mb-2 mt-auto"
      >
        <b-row>
          <!-- Pagination -->
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalEmails"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="loadMessages"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </template>
    <preview-mail
      :additional-mails="additionalMails"
      :subject="subject"
      :message="message"
      :send-to="sendTo"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
  BSpinner,
} from 'bootstrap-vue'
import { formatObject, removeHtmlTags } from '@core/utils/utils'
import store from '@/store'
import { GET_BROADCAST_MAILS } from '@/store/modules/notification.module'
import { apiToastWarning } from '@/@core/utils/toast'
import { utils } from '@kingpin-global/kingpin-utils-frontend'
import SpeakerIcon from '@/@core/assets/svg-components/SpeakerIcon.vue'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import PreviewMail from './PreviewMail.vue'

const { formattedDate } = utils

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BSpinner,
    SpeakerIcon,
    PreviewMail,
  },
  data() {
    const tableColumns = [
      {
        key: 'sentDate',
        label: 'Date',
      },
      {
        key: 'recipientCount',
        label: 'Recipients',
      },
      {
        key: 'subject',
        label: 'Subject',
      },
      {
        key: 'message',
        label: 'body',
      },
    ]
    return {
      isLoaded: false,
      currentPage: 1,
      perPage: 10,
      totalEmails: 0,
      tableColumns,
      emailList: [],
      formattedDate,
      removeHtmlTags,
      additionalMails: [],
      subject: '',
      message: '',
      sendTo: {},
    }
  },
  computed: {
    hasPagination() {
      return this.totalEmails > 10
    },
  },
  created() {
    this.loadMessages()
  },
  methods: {
    loadMessages() {
      const params = {
        page: this.currentPage - 1,
      }
      store
        .dispatch(GET_BROADCAST_MAILS, params)
        .then(res => {
          this.emailList = res.data.data.emails
          this.totalEmails = res.data.data.count
          this.isLoaded = true
        })
        .catch(err => {
          apiToastWarning(err)
          this.isLoaded = false
        })
    },
    openPreviewMail(data) {
      this.sendTo = data.recipients
      this.subject = data.subject
      this.message = data.message
      this.additionalMails = data.recipients?.additionalEmails || []
      const recipientList = [
            ...new Set(Object.values(data.recipients).flat(1)),
          ].toString()
      analytics.track(constants.TRACKS.ACTIONS.BRAND_VIEWS_SENT_MESSAGE, formatObject({
        subject: data.subject, message: removeHtmlTags(data.message), recipientList, sentDate: data.sentDate,
        }))
      this.$bvModal.show('preview-mail')
    },
  },
}
</script>

<style lang="scss" scoped>
.text-truncate {
  max-width: 400px;
}
.speaker-icon {
  height: 25px;
  width: 25px;
}
</style>
