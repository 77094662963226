<template>
  <svg
    width="17"
    height="15"
    viewBox="0 0 17 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9008 4.67474V9.47474C15.2608 9.47474 16.3008 8.43474 16.3008 7.07474C16.3008 5.71474 15.2608 4.67474 13.9008 4.67474ZM7.50078 3.87474H1.90078C1.02078 3.87474 0.300781 4.59474 0.300781 5.47474V8.67474C0.300781 9.55474 1.02078 10.2747 1.90078 10.2747H2.70078V12.6747C2.70078 13.5547 3.42078 14.2747 4.30078 14.2747H5.90078V10.2747H7.50078L10.7008 13.4747H12.3008V0.674744H10.7008L7.50078 3.87474Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'SpeakerIcon',
}
</script>
