<template>
  <b-modal
    id="preview-mail"
    modal-class="custom-modal-variant"
    centered
    size="lg"
    hide-footer
    header-class="py-1"
    hide-header
  >
    <div class="preview-mail scroll-bar">
      <div
        v-if="recipients"
        class="heading"
      >
        Sent To
        <div class="mail-content">
          {{ recipients }}
        </div>
      </div>
      <div
        v-if="additionalMailString"
        class="heading"
      >
        Additional Emails
        <div class="mail-content">
          {{ additionalMailString }}
        </div>
      </div>
      <div class="heading">
        Subject
        <div class="mail-content">
          {{ subject }}
        </div>
      </div>
      <div class="heading">
        Message Preview
        <div
          class="mail-content"
          v-html="message"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import { BModal, VBModal } from 'bootstrap-vue'

const { EMAIL_GROUPS } = c

export default {
  components: {
    BModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    sendTo: {
      type: Object,
      default() {
        return {}
      },
    },
    subject: {
      type: String,
      default: null,
    },
    additionalMails: {
      type: Array,
      default() {
        return []
      },
    },
    message: {
      type: String,
      default: null,
    },
  },
  computed: {
    recipients() {
      let string = ''
      Object.keys(EMAIL_GROUPS).forEach(key => {
        if (this.sendTo[`${key}`]?.length) {
          string += `${EMAIL_GROUPS[key]}(${this.sendTo[key].length}), `
        }
      })
      return string.slice(0, -2)
    },
    additionalMailString() {
      return this.additionalMails.toString()
    },
  },
}
</script>

<style></style>
